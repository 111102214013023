// paper & background
$paper: #ffffff;

// primary
$primaryLight: #f7f1e3;
$primaryMain: #0C67AA;
$primaryDark: #03345f;
$primary200: #90caf9;
$primary800: #1565c0;

// secondary
$secondaryLight: #ede7f6;
$secondaryMain: #673ab7;
$secondaryDark: #5e35b1;
$secondary200: #b39ddb;
$secondary800: #4527a0;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #e3f2fd;
$darkPrimaryMain: #2196f3;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// ==============================|| PANDOO ||============================== //
$ivory: #FEFFF5;

//Warning
$pandooWarningMain: #F58121;
$pandooWarningLight: #ffe8dd;
$pandooWarningDark: #C76311;

//Success
$pandooSuccessLight: #43B61A;
$pandooSuccessDark: #42770D;

//Danger
$pandooDangerLight: #FF473B;
$pandooDangerDark: #C71111;

//Primary
$pandooPrimaryLight: #28B2A5;
$pandooPrimaryDark: #218279;
$pandooPrimaryDarker: #18756C;

//info
$pandooInfoLight: #916DB5;
$pandooInfoDark: #916DB5;


//Active/Inactive
$pandooActive: #565656;
$pandooInactive: #D8D8D8;

//Right/Wrong
$pandooRight: #0F9856;
$pandooWrong: #FF0000;

//text
$pandooTextDark: #414141;
$pandooTextMuted: #777777;
$pandooTextLight: #DDDDDD;


// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;


  // ==============================|| PANDOO ||============================== //

  ivory: $ivory;

  //Warning
  pandooWarningLight: $pandooWarningLight;
  pandooWarningMain: $pandooWarningMain;
  pandooWarningDark: $pandooWarningDark;

  //Success
  pandooSuccessLight: $pandooSuccessLight;
  pandooSuccessDark: $pandooSuccessDark;

  //Danger
  pandooDangerLight: $pandooDangerLight;
  pandooDangerDark: $pandooDangerDark;

  //Primary
  pandooPrimaryLight: $pandooPrimaryLight;
  pandooPrimaryDark: $pandooPrimaryDark;
  pandooPrimaryDarker: $pandooPrimaryDarker;

  //info
  pandooInfoLight: $pandooInfoLight;
  pandooInfoDark: $pandooInfoDark;
  
  //Active/Inactive
  pandooActive: $pandooActive;
  pandooInactive: $pandooInactive;

  //Right/Wrong
  pandooRight: $pandooRight;
  pandooWrong: $pandooWrong;

  //text
  pandooTextDark: $pandooTextDark;
  pandooTextMuted: $pandooTextMuted;
  pandooTextLight: $pandooTextLight;
}
